
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import Company from "@/models/Company";

enum SettingState {
  GENERAL = 'GENERAL',
  COMPANY_NAME = 'COMPANY_NAME',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  PAYMENT = 'PAYMENT'
}

interface NavItemInterface {
  label: any,
  state: SettingState
}

@Component({
  components: {
    EditCompanyDetailsComponent: () => import(
      /* webpackChunkName: "EditCompanyDetailsComponent" */
      '@/components/Settings/CompanySettings/EditCompanyDetails.component.vue'
    ),
    EditCompanyNameComponent: () => import(
      /* webpackChunkName: "EditCompanyNameComponent" */
      '@/components/Settings/CompanySettings/EditCompanyName.component.vue'
    ),
    EditCompanyAddressComponent: () => import(
        /* webpackChunkName: "EditCompanyAddressComponent" */
        '@/components/Settings/CompanySettings/EditCompanyAddress.component.vue'
        ),
    EditCompanyEmailComponent: () => import(
        /* webpackChunkName: "EditCompanyEmailComponent" */
        '@/components/Settings/CompanySettings/EditCompanyEmail.component.vue'
        ),
  },
})
export default class EditProfileCompanyComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private company!: Company;

  @Prop({required: true})
  private showBadge!: boolean;

  private currentState = SettingState.GENERAL;

  /**
   * all the different nav items
   * @private
   */
  private navItems: NavItemInterface[] = [
    {label: this.$t('EDIT_PROFILE.COMPANY_HEADLINES.GENERAL'), state: SettingState.GENERAL},
    {label: this.$t('EDIT_PROFILE.COMPANY_HEADLINES.COMPANY_NAME'), state: SettingState.COMPANY_NAME},
    {label: this.$t('EDIT_PROFILE.COMPANY_HEADLINES.ADDRESS'), state: SettingState.ADDRESS},
    // TODO: removing company address for now, maybe this will be reintroduced in the future
    // {label: this.$t('EDIT_PROFILE.COMPANY_HEADLINES.CHANGE_EMAIL'), state: SettingState.EMAIL}
  ];

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: string): void {
    this.currentState = value as SettingState;
  }

  private isStateActive(value: string) {
    return this.currentState === value as SettingState;
  }
}
